<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">{{ label }}</label>
      <vx-input-group
        prependClasses="border border-solid border-grey border-r-0"
      >
        <template slot="prepend">
          <div class="prepend-text bg-primary btn-addon">
            <vs-button @click="changeType">{{ value.label }}</vs-button>
          </div>
        </template>
        <vs-input
          class="w-full"
          type="number"
          v-model="value.value"
        />
      </vx-input-group>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  props: {
    value: {},
    label:{}
  },
  data () {
    return {
      iterator: this.value.enum.entries()
    }
  },
  methods: {
    changeType () {
      let el = this.iterator.next()
      if (el.done) {
        this.iterator = this.value.enum.entries()
        el = this.iterator.next()
      }
      this.value.type = el.value[1].value
      this.value.label = el.value[1].label
    }
  }
}
</script>