<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">{{ label || valuel.$fields[prop].label}}</label>
      <v-select
        v-model="data"
        @input="input"
        :data-vv-as="name"
        class="vs_select_over"
        :name="name"
        :options="list"
        multiple
        placeholder="Selecione"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span
        class="text-danger text-sm"
        v-show="errors.has('name')"
        >{{ errors.first('name') }}</span
      >
    </vs-col>
  </vs-row>
</template>

<script>
// Store Module
export default {
  props: ['value', 'label', 'options', 'prop'],
  data () {
    return {
      list: this.options || this.value.$fields[this.prop].options,
      data: this.value[this.prop],
      name: 'campo'
    }
  },
  watch: {
    value (value) {
      this.data = value.$getOptionsByField(this.prop)
    }
  },
  methods: {
    input () {
      this.value[this.prop] = this.data.map(e => {
        return e.value
      })
    }
  }
}
</script>

<style lang="scss">
.vs_select_over .vs__dropdown-menu {
  position: relative;
}
.vue_select_drop_size_98 .vs__dropdown-menu {
  max-height: 98px;
}
</style>
